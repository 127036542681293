<template lang="html">
    <setting-block-layout
        @save="onSave"
        @cancel="onCancel"
    >
        <template #body>
            <div class="profile-header-block__inner-row account-settings_checkboxes">
                <p class="profile-header-block--value">
                    <form-checkbox
                        v-model="setting.chk_calls"
                        color="orange darken-2"
                        :label="'Allow phone calls from Support Team'"
                        @input="updateCheckboxes($event, 'chk_calls')"
                    />
                    <form-checkbox
                        v-model="setting.chk_email_notifications"
                        color="orange darken-2"
                        :label="'Allow e-mail notifications (transactional e-mails)'"
                        @input="updateCheckboxes($event, 'chk_email_notifications')"
                    />
                    <form-checkbox
                        v-model="setting.chk_email_marketing"
                        color="orange darken-2"
                        :label="'Agree to receive e-mails (newsletter, discount codes, promotional materials related to our services etc.)'"
                        @input="updateCheckboxes($event, 'chk_email_marketing')"
                    />
                    <form-checkbox
                        v-model="setting.chk_sms_notifications"
                        color="orange darken-2"
                        :label="'Receive sms-notifications'"
                        @input="updateCheckboxes($event, 'chk_sms_notifications')"
                    />
                </p>
            </div>
        </template>
    </setting-block-layout>
</template>

<script>
import { createNamespacedHelpers, mapGetters } from 'vuex';
import { eventBus } from '@/helpers/event-bus';

import SettingBlockLayout from '@/components/account-settings/SettingBlockLayout';
import {
    ACTION_CLIENT_UPDATE_SETTINGS
} from '@/store/modules/client/action-types';

const {
    mapActions: mapClientActions
} = createNamespacedHelpers('client');

export default {
    components: {
        SettingBlockLayout
    },
    metaInfo: {
        title: 'Notification settings'
    },

    data() {
        return {
            setting: {}
        }
    },
    computed: {
        ...mapGetters('client', [
            'getterChkNotifications'
        ])
    },
    created() {
        this.setting = { ...this.getterChkNotifications }
    },
    methods: {
        ...mapClientActions({
            updateSettings: ACTION_CLIENT_UPDATE_SETTINGS
        }),
        onCancel() {
            this.$router.push({ name: 'profile' })
        },
        async onSave() {
            try {
                await this.updateSettings({ ...this.setting })
                this.$router.push({ name: 'profile' })
                eventBus.$emit('showSnackBar', 'Your notification settings have been saved', 'success');
            } catch (err) {
                eventBus.$emit('showSnackBar', 'Failed to update settings', 'error');
            }
        },
        updateCheckboxes(value, key) {
            this.setting[key] = Number(value)
        }
    }
}
</script>

<style lang="scss">
.account-settings_checkboxes {
    .checkox-block {
        margin-bottom: 30px;
        &:last-child {
            margin-bottom: 0;
        }
        &:first-child {
            margin-top: 20px;
        }
    }
}
</style>
